import React, { useState, useRef, useEffect } from 'react';
import '../Css/ChatBody.css'
import Logo from '../Assets/iLink-Logo.png';
import Close from '../Assets/close.png';
import Send from '../Assets/SendMessageIcon.png';
import UserMsg from './UserMsg';
import BotMsg from './BotMsg';
import { v4 as uuidv4 } from 'uuid';

export default function ChatBody(props) {
    const messagesEndRef = useRef(null)
    const [message, setMessage] = useState([])
    const [showMsg, setShowMsg] = useState([])
    const [inputDisable, setInputDisable] = useState(false)
    const [msgGenerate, setMsgGenerate] = useState(false)
    const [sessionId, setSessionId] = useState(uuidv4())
    const [streamText, setStreamText] = useState('')
    const [msgComplete, setMsgComplete] = useState(false)
    const [chatHistory, setChatHistory] = useState([])
    const [nquestions, setnquestions] = useState(0);

    //To set User Message into onClick send Icon
    const sendMsg = async () => {
        setMsgGenerate(true);
        setInputDisable(true);
        const userMessage = { message: message, sender: true };
        // Copy the previous messages and add the user's message
        setShowMsg(prevShowMsg => [...prevShowMsg, userMessage]);
        // const history ={question:message}
        // setChatHistory(prevHistory => [...prevHistory,history])
        processBotResponse();
    };

    const processBotResponse = async () => {
        setMsgComplete(false)
        setMessage('')
        try {
            const data = {
                question: message,
                session_id: sessionId,
                n_questions: nquestions.toString()
            };

            const response = await fetch("https://ilinkchatbot.azurewebsites.net/chat", {
                method: "POST",
                responseType: "stream",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.body) {
                throw new Error("Streaming not supported.");
            }

            const reader = response.body.getReader();

            let partialData = ''
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    console.log("Stream has ended.");
                    break;
                }

                const chunk = String.fromCharCode.apply(null, value);
                partialData += chunk;



                // Check if the received data forms a complete JSON object
                const jsonObjects = partialData.split('}'); 

                // Process and log each complete JSON object
                for (let i = 0; i < jsonObjects.length - 1; i++) {

                    const jsonObject = jsonObjects[i] + '}';

                    try {
                        const jsonData = JSON.parse(jsonObject);
                        if (jsonData.type === 'start') {
                            const botMessage = { message: jsonData.value, sender: false };
                            setShowMsg(prevShowMsg => [...prevShowMsg, botMessage]);
                            setMsgGenerate(false);
                        } else if (jsonData.type === 'streaming') {
                            setShowMsg((prevShowMsg) => {
                                const updatedMessages = [...prevShowMsg];
                                const lastMessage = updatedMessages[updatedMessages.length - 1];

                                // Check if the new text is different from the last part of the existing message
                                if (!lastMessage.message.endsWith(jsonData.value)) {
                                    lastMessage.message += jsonData.value;
                                }

                                return updatedMessages;
                            });
                            setStreamText(prevText => prevText + jsonData.value)

                        }
                        if (jsonData.type === 'end') {
                            setMsgComplete(true)
                            setInputDisable(false);
                        }

                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                }

                partialData = jsonObjects[jsonObjects.length - 1];
            }
            setnquestions(nquestions + 1);
        } catch (error) {
            console.error("Error:", error);
        }
    }        


    useEffect(() => {
        if (msgComplete && streamText.length > 0) {
            setShowMsg((prevShowMsg) => {
                const updatedMessages = [...prevShowMsg];
                const lastMessage = updatedMessages[updatedMessages.length - 1];

                lastMessage.message = streamText;
                return updatedMessages;
            });
            setStreamText('')
        }

        // if (msgComplete === true) {
        //     setChatHistory((prevHistory) => {
        //         const lastMessage = prevHistory[prevHistory.length - 1];

        //         // Append the answer to the existing last object
        //         const updatedLastMessage = { ...lastMessage, answer: streamText };

        //         // Replace the last object in the array with the updated one
        //         return [...prevHistory.slice(0, -1), updatedLastMessage];
        //     });

        //     setMsgComplete(false)
        //     setStreamText('')
        // }
    }, [msgComplete])


    // useEffect(() => {
    //     console.log('chat history', chatHistory)
    // }, [chatHistory])

    //Hit on Enter keyword it will be send message
    const handleEvent = (event) => {
        if (event.key === 'Enter') {
            if (message.length > 0)
                sendMsg()
        }
    }

    //Scroll to the bottom whenever a new message is added
    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [showMsg]);

    const closeIframe = () => {
        window.parent.postMessage("closeIframe", "*");
        setSessionId(uuidv4())
        setShowMsg([])
        setMsgGenerate(false)
        setMsgComplete(false)
        setInputDisable(false)
        setnquestions(0)
    };

    return (
        <>
            <div className='chatPage'>
                <div className='chatHeader'>
                    <div className='head'>
                        <img className='logo' src={Logo} alt="" />
                        <div className='closeDiv' onClick={closeIframe}>
                            <img className='icon' src={Close} alt='' />
                        </div>
                    </div>
                </div>

                <div className='chatBody'>
                    <BotMsg msg="Hi! I am Echo. Would you like one of our customer service representatives to reach out to you?" />
                    {
                        showMsg.map((data) => (
                            data.sender ? <UserMsg msg={data.message} /> : <BotMsg msg={data.message} />
                        ))
                    }

                    {
                        msgGenerate === true ? <BotMsg msg="Echo is composing a message" isComposing={true} /> : null
                    }
                    <div ref={messagesEndRef} />
                </div>

                <div className='chatFooter'>
                    <div className='footer'>
                        <input className='inputMessage' type='text' placeholder='Ask Something...' value={message} onKeyDown={handleEvent} onChange={(e) => { setMessage(e.target.value) }} disabled={inputDisable} />
                        {
                            inputDisable ? null : message.length > 0 ?
                                <div className='sendMessageIcon' onClick={sendMsg}>
                                    <img className='icon' src={Send} alt="" />
                                </div> : null
                        }
                    </div>
                </div>

            </div>
        </>
    )
}
