import './App.css';
import ChatBody from './Components/ChatBody';

function App() {
  return (
    <ChatBody />
  );
}

export default App;
