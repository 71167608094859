import React, { useState, useEffect } from 'react';
import '../Css/ChatComp.css';
import BotIcon from '../Assets/BotIcon.png';

export default function BotMsg(props) {
  const [dots, setDots] = useState('');


  const messageLines = props.msg.split('\n').map((line, index) => {
    if (line.startsWith('http')) {
      return (
        
        <div key={index}>
          &#8226;{' '}
          <a href={line} target="_blank" rel="noopener noreferrer">
            {line}
          </a>
        </div>
        
      );
    } else {
      return (
        <span key={index}>
          {line + dots}
          <br />
        </span>
      );
    }
  });
  // Set animation for three dots
  useEffect(() => {
    if (props.isComposing === true) {
      const interval = setInterval(() => {
        setDots((prevDots) => {
          if (prevDots === '...') return '';
          return prevDots + '.';
        });
      }, 1000);
      return () => clearInterval(interval);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div style={{ justifyContent: 'left', display: 'flex' }}>
      <div className="botMsg">
        <div className="botImg">
          <img src={BotIcon} width="30" height="30" alt="" />
        </div>
        <div style={{ wordWrap: 'break-word', maxWidth: '86%', whiteSpace: 'pre-wrap', paddingLeft:"4px" }}>
          <span>
            {messageLines}
          </span>
        
        </div>
      </div>
    </div>
  );
}
